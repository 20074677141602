<template>
  <v-container
    class="error-page fill-height"
    tag="section"
  >
    <v-row
      class="text-center"
      justify="center"
    >
      <v-col>
        <div class="error-page">
          <div class="error-page-content">
            <div class="image"></div>
              <h1>Sorry!</h1>
              <h2>It looks like something has gone wrong. </h2>
              <p>Please try again or contact us.</p>
              <v-btn depressed to="/" >
              Back to homepage
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PagesError'
}
</script>

<style lang="scss" scoped>
.error-page-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.error-page {
  position: relative;

  .error-page-content {
    max-width: 560px;
    width: 100%;
    padding-left: 160px;
    line-height: 1.2;

    .image {
      position: absolute;
      left: 0;
      top: 0;
      width: 140px;
      height: 150px;
      background-image: url(https://frogv3.s3.eu-west-2.amazonaws.com/website/frog/images/frog.svg);
      background-size: cover;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-transform: scale(2);
        -ms-transform: scale(2);
        transform: scale(2);
        border-radius: 50%;
        background-color: #ededed;
        z-index: -1;
        left: 20px;
      }
    }

    h1 {
      font-size: 65px;
      font-weight: 700;
      margin-top: 0px;
      margin-bottom: 10px;
      color: #000;
      text-transform: uppercase;
    }

    h2 {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      text-transform: uppercase;
      color: #000;
    }

    p {
      color: #000;
      font-weight: 400;
    }
  }
}
@media only screen and (max-width: 767px) {
  .error-page-content {
    .image {
      width: 110px;
      height: 120px;
    }

    padding: 110px 15px 15px 0;
  }
}
</style>
